/**
 * Add your config changes here.
 * @module config
 * @example
 * export const settings = {
 *   ...defaultSettings,
 *   port: 4300,
 *   listBlockTypes: {
 *     ...defaultSettings.listBlockTypes,
 *     'my-list-item',
 *   }
 * }
 */

import SearchInCatalogsView from '@package/components/Blocks/SearchInCatalogs/View';
import SearchInCatalogsEdit from '@package/components/Blocks/SearchInCatalogs/Edit';
import linkSvg from '@plone/volto/icons/link.svg';
import applyItaliaConfig from '@italia/config/italiaConfig';
import '@plone/volto/config';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    isMultilingual: true,
    supportedLanguages: ['it', 'en', 'fr'],
    defaultLanguage: 'it',
    italiaThemeViewsConfig: {
      ...config.settings.italiaThemeViewsConfig,
      imagePosition: 'documentBody',
    }, // possible values: afterHeader, documentBody
    matomoSiteId: '6vqEwjW3QO',
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
  };

  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: {
      ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
      last_updated: '2023-05-23T10:00:00+00:00',
      technical: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
        choices: [
          {
            config_key: 'MATOMO',
            text: {
              it: {
                title: 'Matomo',
                description:
                  "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
              },
              en: {
                title: 'Matomo',
                description:
                  'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
              },
              fr: {
                title: 'Matomo',
                description:
                  "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
              },
            },
          },
          /* ...config.settings[
            'volto-gdpr-privacy'
          ].defaultPanelConfig.technical.choices.filter(
            (f) => f.config_key !== 'GANALYTICS',
          ), */
        ],
      },
    },
  };

  config.blocks.blocksConfig = {
    ...config.blocks.blocksConfig,
    searchInCatalogs: {
      id: 'searchInCatalogs',
      title: 'Cerca nei cataloghi',
      icon: linkSvg,
      group: 'homePage',
      view: SearchInCatalogsView,
      edit: SearchInCatalogsEdit,
      restricted: false,
      mostUsed: false,
      security: {
        addPermission: [],
        view: [],
      },
      sidebarTab: 1,
    },
  };
  config.blocks.blocksConfig.listing.listing_bg_colors = []; //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
  config.blocks.blocksConfig.listing.listing_items_colors = [
    { name: 'white', label: 'Bianco' },
    { name: 'highlight', label: 'Arancione' },
  ]; //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}

  config.settings.siteProperties = {
    ...config.settings.siteProperties,
    siteTitle: 'Biblioteche Pianura Est',
    siteSubtitle: {
      default: 'CONOSCERE, CREARE, RICREARSI',
      it: 'CONOSCERE, CREARE, RICREARSI',
      en: 'KNOWING, CREATING, RECREATING',
    },
    parentSiteTitle: {
      default: 'Città metropolitana di Bologna',
      it: 'Città metropolitana di Bologna',
      en: 'Metropolitan city of Bologna',
    },
    parentSiteURL: 'https://www.cittametropolitana.bo.it/',
    subsiteParentSiteTitle: 'Biblioteche Pianura Est - Bologna',
    headerslimTertiaryMenu: {
      it: [
        {
          url: '/it/biblioteca/cerca-nei-cataloghi',
          title: 'Cerca nei cataloghi',
        },
        {
          url: '/it/biblioteca/chiedi-al-bibliotecario',
          title: 'Chiedi al bibliotecario',
        },
        { url: '/it/biblioteca/contatti', title: 'Contatti' },
      ],
      en: [
        {
          url: '/en/library/search-in-catalogs',
          title: 'Search in catalogs',
        },
        { url: '/en/library/ask-the-librarian', title: 'Ask the librarian' },
        { url: '/en/library/contacts', title: 'Contacts' },
      ],
      fr: [
        {
          url: '/fr/bibliotheque/rechercher-dans-les-catalogues',
          title: 'Rechercher dans les catalogues',
        },
        {
          url: '/fr/bibliotheque/demander-au-bibliothecaire',
          title: 'Demander au bibliothécaire',
        },
        { url: '/fr/bibliotheque/contacts', title: 'Contacts' },
      ],
    },
  };

  config.settings['volto-gdpr-privacy'].defaultPanelConfig.text.it.description =
    "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookies analitici per raccogliere informazioni sull'uso del sito da parte degli utenti.  Utilizza anche cookies di profilazione dell'utente per fini statistici. Per i cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Impostazioni'. Per saperne di più, su come disabilitare i cookies oppure abilitarne solo alcuni, consulta la nostra <a href='/it/privacy-policy'>Cookie Policy</a>.";

  return config;
}
