/**
 * HeaderSlim component.
 * @module components/ItaliaTheme/Header/HeaderSlim
 */

/*
 ------CUSTOMIZATIONS: 
 - show ArLogin only if it's not subsite
 */

import React from 'react';
import { useSelector } from 'react-redux';
import {
  ParentSiteMenu,
  LanguageSelector,
  ArLogin,
  TertiaryMenu,
} from '@italia/components/ItaliaTheme';

const HeaderSlimRightZone = () => {
  const subsite = useSelector((state) => state.subsite?.data);
  return (
    <>
      <ParentSiteMenu />
      {!subsite && <TertiaryMenu />}
      <LanguageSelector />
      {!subsite && <ArLogin />}
    </>
  );
};

export default HeaderSlimRightZone;
