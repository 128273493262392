import React from 'react';

const Background = (props) => (
  <div className="searchSections-background">
    <figure className="img-wrapper">
      {props?.image && (
        <img
          src={`data:${props?.image['content-type']};${props?.image?.encoding},${props?.image?.data}`}
          alt="imgalt"
          title="imgtitle"
        ></img>
      )}
    </figure>
  </div>
);

export default Background;
