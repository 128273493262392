import React from 'react';
import PropTypes from 'prop-types';

export const ListingCategory = ({ category, item }) => {
  let text = item.city || item.luoghi_correlati?.[0]?.city || category;

  return text ? <>{text}</> : null;
};

ListingCategory.propTypes = {
  category: PropTypes.string,
  item: PropTypes.object,
};

export default ListingCategory;
