import React from 'react';
//eslint-disable-next-line import/no-unresolved
import logo from '~/components/Layout/images/logo-white.png?width=100';

const Logo = () => (
  <img
    src={logo}
    width="100"
    height="62"
    alt="Biblioteche Pianura Est Logo"
    className="icon"
  />
);

export default Logo;
